import React from 'react';
import './header.css';

const Header = () => {
    return (
        <div className="header">
            <img src="https://dummyimage.com/100x100/000/fff" alt="Logo" className="logo" />
            <p className="name">Func-it</p>
        </div>
    );
};

export default Header;
