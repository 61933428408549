import React from 'react';
import './App.css';
import {client} from './appoloClient';
import {ApolloProvider} from '@apollo/client';
import './index.css';
import Header from './components/header';
import Window from './components/window';
import Footer from './components/footer';

function App() {
    return (
        <div className="App">
            <ApolloProvider client={client}>
                <Header/>
                <Window/>
                <Footer/>
            </ApolloProvider>,
        </div>
    );
}

export default App;
