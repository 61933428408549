import React, {useEffect, useState} from 'react';
import './window.css';
import {useSubscription} from '@apollo/client';
import QRCode from 'react-qr-code';
import {TakeItDocument} from "../generated/graphql";

const Window: React.FC = () => {
    const [refreshKey, setRefreshKey] = useState(0); // State to control the remounting

    // Conditional rendering based on refreshKey
    const SubscriptionComponent = () => {
        const { data, loading, error } = useSubscription(TakeItDocument);

        useEffect(() => {
            if (data && data.botRegistrationUpdate?.QRCode === "") {
                // Trigger a 'refresh' by updating the refreshKey
                setRefreshKey(prevKey => prevKey + 1);
            }
        }, [data]);

        if (loading) return <p>Loading...</p>;
        if (error) return <p>Error: {error.message}</p>;

        return (
            <div className={"content-container"}>
                {data?.botRegistrationUpdate?.QRCode && (
                    <QRCode value={data.botRegistrationUpdate.QRCode}/>
                )}
            </div>
        );
    };

    return (
        <div className="window">
            <div className="titles">
                <h1 className="title">Hello!</h1>
                <h2 className="subtitle">Open this link in any other screen and scan the Qr Code</h2>
                <p className="paragraph">You may not open this window in the same device as your whatsapp application</p>
            </div>
            {/* Render the SubscriptionComponent with key */}
            <SubscriptionComponent key={refreshKey} />
        </div>
    );
};

export default Window;