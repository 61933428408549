import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export enum AmalgamType {
  Absent = 'absent',
  Service = 'service'
}

export type InitNewDopplerRequest = {
  amalgamType: AmalgamType;
  data: Array<MapStringString>;
  description: Scalars['String']['input'];
  instructions: Array<MapStringString>;
  language: Scalars['String']['input'];
  name: Scalars['String']['input'];
  owner: Scalars['String']['input'];
  race: Scalars['String']['input'];
  style: Scalars['String']['input'];
};

export type LinkDeviceToDopplerRequest = {
  dopplerID: Scalars['String']['input'];
};

export type MapStringString = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  initNewDoppler: NewDopplerId;
};


export type MutationInitNewDopplerArgs = {
  input: InitNewDopplerRequest;
};

export type NewDopplerId = {
  __typename?: 'NewDopplerID';
  id: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']['output']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  botRegistrationUpdate?: Maybe<WhatsappRegisterBotResponse>;
  linkDeviceToDoppler: WhatsappRegisterBotResponse;
};


export type SubscriptionLinkDeviceToDopplerArgs = {
  input?: InputMaybe<LinkDeviceToDopplerRequest>;
};

export type WhatsappRegisterBotRequest = {
  number?: InputMaybe<Scalars['Int']['input']>;
};

export type WhatsappRegisterBotResponse = {
  __typename?: 'WhatsappRegisterBotResponse';
  QRCode: Scalars['String']['output'];
};

export type WhatsappRegisterDopplerId = {
  __typename?: 'WhatsappRegisterDopplerID';
  id: Scalars['String']['output'];
};

export type TakeItSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type TakeItSubscription = { __typename?: 'Subscription', botRegistrationUpdate?: { __typename?: 'WhatsappRegisterBotResponse', QRCode: string } | null };


export const TakeItDocument = gql`
    subscription TakeIt {
  botRegistrationUpdate {
    QRCode
  }
}
    `;

/**
 * __useTakeItSubscription__
 *
 * To run a query within a React component, call `useTakeItSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTakeItSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTakeItSubscription({
 *   variables: {
 *   },
 * });
 */
export function useTakeItSubscription(baseOptions?: Apollo.SubscriptionHookOptions<TakeItSubscription, TakeItSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<TakeItSubscription, TakeItSubscriptionVariables>(TakeItDocument, options);
      }
export type TakeItSubscriptionHookResult = ReturnType<typeof useTakeItSubscription>;
export type TakeItSubscriptionResult = Apollo.SubscriptionResult<TakeItSubscription>;