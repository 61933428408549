import React from 'react';
import './footer.css'; // Importa los estilos del componente

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="footer-links">
                    <a href=".">About</a>
                    <a href=".">Blog</a>
                    <a href=".">Contact</a>
                </div>
                <div className="footer-social">
                    <a href="https://github.com"><i className="fab fa-github"></i></a>
                    <a href="https://twitter.com"><i className="fab fa-twitter"></i></a>
                    <a href="https://linkedin.com"><i className="fab fa-linkedin"></i></a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
