import {ApolloClient, InMemoryCache, HttpLink, split} from "@apollo/client";
import {getMainDefinition} from "@apollo/client/utilities";
import { createClient } from 'graphql-ws';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';

const httpUri = process.env.REACT_APP_GRAPHQL_HTTP_URI;
const wsUri = process.env.REACT_APP_GRAPHQL_WS_URI!;

const httpLink = new HttpLink({
    uri: httpUri, // Use the HTTP URI from the environment variable
});

console.log("wsUri:", wsUri);
console.log("httpUri:", httpUri);

const wsLink = new GraphQLWsLink(
    createClient({
        url: wsUri, // Use the WebSocket URI from the environment variable
        on: {
            error: (error) => {
                console.error("WebSocket Error:", error);
            },
            // Add other event handlers as needed
        },
    }),
);

const splitLink = split(
    ({query}) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink,
    httpLink,
);

export const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
});